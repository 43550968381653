<template>
  <div
    style="background-color:var(--color-beige); color:var(--color-braun);"
  >
    <div class="bg bg-repeat-x h-10 lg:h-16" />
    <div class="max-w-5xl mx-auto px-5 lg:px-10 py-10 lg:py-32 text-center tracking-widest leading-8">
      <div class="my-10 mx-auto grid w-2/6">
        <div class="flex flex-col items-center">
          <RouterLink
            class="opacity-30 mb-5 w-4/6"
            to="/"
          >
            <img
              class="w-full"
              src="@/assets/Grafiken/Genuss-Cafe-Icon-Braun.svg"
            >
          </RouterLink>
          <RouterLink
            to="/"
          >
            <img
              class="w-full"
              src="@/assets/Grafiken/Genuss-Cafe-Logo-Braun.svg"
            >
          </RouterLink>
        </div>
      </div>
      <RouterLink
        class="relative inline-block text-sm font-medium group focus:outline-none focus:ring uppercase mb-10"
        style="color:var(--color-braun);"
        to="/"
      >
        <span class="absolute inset-0 border border-current" />
        <span class="block px-12 py-3 border transition-transform border-current group-hover:-translate-x-1 group-hover:-translate-y-1">
          Startseite
        </span>
      </RouterLink>
      <div class="text-5xl md:text-8xl font-bold mb-10 font-['Boska']">
        Impressum
      </div>
      <div class="text-left">
        <div>
          <h2 class="font-bold">
            Cafe Genuss OG
          </h2>
          <p>Mozartplatz 2a<br>5340 St. Gilgen<br>Österreich</p>
          <br>
          <p>
            Telefon: +43 660 41 11 650 <br> E-Mail: <a
              href="mailto:info@genuss-cafe.at"
            >info@genuss-cafe.at</a>
          </p>
          <br>
          <p>UID Nr.: ATU78179016<br> Firmenbuchnummer: FN 581911 b <br> Gerichtsstand: Landesgericht Salzburg</p>
          <p>
            Unternehmensgegenstand: Gastronomie<br>
            <a
              href="https://firmen.wko.at/cafe-genuss-og/salzburg/?firmaid=7b9203a3-0a4c-4bec-8e23-955d1212892e&standortid=4&standortname=salzburg%20%28bundesland%29&suchbegriff=cafe%20genuss%20og"
              target="_blank"
            >Informationspflicht lt. § 5 Abs. 1 E-Commerce-Gesetz</a>
          </p>
          <h3
            class="font-bold mt-5"
          >
            Design, Konzeption &amp; Umsetzung:
          </h3>
          <p>
            Eyedea Werbe GmbH <br> Söllheimerstraße 16, Haus 8 / Top 7 <br> 5020 Salzburg <br> +43 (0)662 89 00 58–40 <br>
            <a
              href="mailto:office@eyedea.at"
              target="_blank"
            >office@eyedea.at</a>
            <br>
            <a
              href="http://www.eyedea.at"
              target="_blank"
            >www.eyedea.at</a>
          </p>
          <h3 class="mt-5">
            Rechtliche Hinweise:
          </h3>
          <div>
            <p class="mt-5">
              <strong>Haftungsausschluss</strong>
            </p>
            <p>Alle auf dieser Website enthaltenen Informationen wurden mit großer Sorgfalt geprüft. Die Website wird regelmäßig aktualisiert. Der Eigentümer übernimmt jedoch keine Garantie für die Richtigkeit oder Vollständigkeit des Inhaltes dieser Website.</p>
            <p class="mt-5">
              <strong>Urheberrecht</strong>
            </p>
            <p>Alle Inhalte auf dieser Website sind urheberrechtlich geschützt. Durch Dritte geschützte Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzerrechten der jeweiligen eingetragenen Eigentümer.</p>
            <p class="mt-5">
              <strong>Haftung für Links</strong>
            </p>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden derartige Links umgehend entfernt.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Imprint'
}
</script>
